import CH from './lang/CH.json'
export default {
  install(Vue){
    Vue.mixin({
      data(){
        return{
          language:CH,
        }
      }
    })
  }
}
