import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
  },
  state: {
    language:'',
  },

  mutations: {
    language(state, info) {
      state.language = info;
    }
  },
  actions: {
    setlanguage({commit,state,getters},props){
      commit('language',props);
    }
  },
  getters
})
