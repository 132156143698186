import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:{name:'Home'},
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  }
]
const router = new VueRouter({
  routes
})
router.beforeEach((to,from,next)=>{
  var u = navigator.userAgent;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端或者uc浏览器
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  // console.log('是否是Android：'+isAndroid);
  // console.log('是否是iOS：'+isiOS);
  let type='';
  isAndroid?type='Android':isiOS?type='iOS':type='Other'
  console.log(to.path)
  if(to.path=='/about' && type=='Other'){
    next('/Home');
  }else if(to.path=='/Home' && type!='Other'){
    next('/about');
  }else{
    next()
  }

})
export default router
