<template>
  <div class="home">
    <Header @onInternationa="onInternationa" ref="myChild"></Header>
    <div class="top_div">
      <div class="bg_top">
        <p class="title_p" :class="{'title_pen':language.type=='EN'}">{{language.title1}}</p>
        <p class="content_1" :class="{'content_1en':language.type=='EN'}">{{language.content}}</p>
        <ul class="oneUl">
          <li v-for="item in language.list1" :key="item.id">
            <img :src="require(`@/assets/images/${item.imgId}.png`)" alt="">
            <p class="p1">{{item.title}}</p>
            <hr class="hr"/>
            <p class="p2" :class="{'p2en':language.type=='EN'}">{{item.text}}</p>
          </li>
        </ul>
      </div>
    </div>
    <div style="min-width: 1440px;margin: 0 auto">
      <div class="content1">
        <div class="title" id="content1">
          <img :src="title_l" alt="">
          <p class="headline">{{language.content1.headline}}</p>
          <img :src="title_r" alt="">
        </div>
        <p class="caption">{{language.content1.caption}}</p>
        <el-carousel class="carousel" :interval="10000">
          <el-carousel-item v-for="(itemList,index) in language.content1.List" :key="itemList.id">
            <ul class="carouseUl">
              <li v-for="(item,index) in itemList.inList" :key="item.id">
                <img class="headPortrait" :src="require(`@/assets/images/${item.imgName}.png`)" alt="">
                <h1>{{item.name}}</h1>
                <div class="inlabel">
                  <h3>{{item.specialty}}</h3>
                  <h3>{{item.exp}}</h3>
                </div>
                <p class="p4" :class="{'p4en':language.type=='EN'}">{{item.text}}</p>
                <img v-if="item.videourl" @click="videourl=item.videourl,showVideo=true" @mouseleave="item.icon_play='icon_play'" @mouseover="item.icon_play='icon_playred'" class="icon_play" :src="require(`@/assets/images/${item.icon_play}.png`)" alt="">
              </li>
            </ul>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="content2">
        <div class="title" id="content2">
          <img :src="title_l" alt="">
          <p class="headline">{{language.content2.headline}}</p>
          <img :src="title_r" alt="">
        </div>
        <p class="caption">{{language.content2.caption}}</p>
        <ul class="inul">
          <li class="li"  v-for="(item,index) in language.content2.List" :key="item.id">
            <template v-if="index%2==0">
              <div>
                <img class="FrameL" :src="Frame_L" alt="">
                <p class="p5" :class="{'p5en':language.type=='EN'}">{{item.title}}</p>
                <p class="p6">{{item.content}}</p>
                <p class="p7">{{item.buttonText}}</p>
              </div>
              <img class="online" :src="online02" alt="">
            </template>
            <template v-else>
              <img class="online" :src="online01" alt="">
              <div>
                <img class="FrameR" :src="Frame_R" alt="">
                <p class="p5" :class="{'p5en':language.type=='EN'}" style="margin-top: 96px">{{item.title}}</p>
                <p class="p6">{{item.content}}</p>
                <p class="p7">{{item.buttonText}}</p>
              </div>
            </template>
          </li>
        </ul>
      </div>
      <div class="content3">
        <div class="title" id="content3">
          <img :src="title_l" alt="">
          <p class="headline">{{language.content3.headline}}</p>
          <img :src="title_r" alt="">
        </div>
        <p class="caption">{{language.content3.caption}}</p>
        <el-carousel class="carousel" :interval="10000">
          <el-carousel-item v-for="itemList in language.content3.List" :key="itemList.id">
              <ul class="carousediv">
                <li v-for="(item,index) in itemList.inList" :key="index">
                  <img :src="require(`@/assets/images/${item.imgName}.png`)" alt="" >
                </li>
              </ul>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="content4">
        <div class="title" id="content4">
          <img :src="title_l" alt="">
          <p class="headline">{{language.content4.headline}}</p>
          <img :src="title_r" alt="">
        </div>
        <p class="caption">{{language.content4.caption}}</p>
        <div class="indiv">
          <hr class="hr"/>
          <p class="p8">{{language.content4.title}}</p>
          <p class="p9">{{language.content4.text}}</p>
        </div>
      </div>
    </div>
    <div class="buttom_div">
      <div class="bg_buttom">
        <div class="div_L">
          <p class="p11">ePatch Education</p>
          <p class="p11 p12">{{language.buttom_div.mail}}：bestnewshiya@gmail.com</p>
          <p class="p11 p12">{{language.buttom_div.customer}}：Best-ePath</p>
          <p class="p11 p12">{{language.buttom_div.more}}</p>
        </div>
        <div class="div_R">
         <div>
           <img :src="customer" alt="">
           <p class="p10">公众号</p>
         </div>
         <div>
           <img :src="PublicAccounts" alt="">
           <p class="p10">客服微信</p>
         </div>
       </div>
      </div>
      <p class="footnote">©ePatch Education</p>
    </div>
    <div class="video" v-if="showVideo">
      <div class="vidio">
        <video :src="require(`@/assets/videos/${videourl}.mp4`)" autoplay controls></video>
        <i @click="showVideo=false" class="el-icon-error"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import EN from "../assets/lang/EN.json";
import CH from "../assets/lang/CH.json";
export default {
  name: 'Home',
  data(){
    return{
      title_l:require('@/assets/images/title-l.png'),
      title_r:require('@/assets/images/title-r.png'),
      icon_play:require('@/assets/images/icon_play.png'),
      icon_playred:require('@/assets/images/icon_playred.png'),
      List:[],
      int:0,
      videourl:'Anna',
      showVideo:false,
      Frame_L:require('@/assets/images/Frame_L.png'),
      Frame_R:require('@/assets/images/Frame_R.png'),
      online01:require('@/assets/images/online01.png'),
      online02:require('@/assets/images/online02.png'),
      customer:require('@/assets/images/customer.png'),
      PublicAccounts:require('@/assets/images/PublicAccounts.png'),
      Internationalization:sessionStorage.getItem('Internationalization')
    }
  },
  components:{
    Header
  },
  methods:{
    onInternationa(language){
      this.language=language
    },
    Select(anchorId){
      if(anchorId=="scrTo"){
        window.scrollTo(0,0)
      }else{
        document.querySelector(anchorId).scrollIntoView({
          behavior: "smooth", // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
          block: "center", // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
          inline: "nearest" // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
        })
      }
    },
    onScoll(){
      let scrollTop = document.documentElement.scrollTop;
      if(scrollTop<=679){
        this.$refs.myChild.onactiveIndex("0")
      }
      if(scrollTop>=1557){
        this.$refs.myChild.onactiveIndex("1")
      }
      if(scrollTop>=2640){
        this.$refs.myChild.onactiveIndex("2")
      }
      if(scrollTop>=3932){
        this.$refs.myChild.onactiveIndex("3")
      }
      if(scrollTop<=0){
        this.$refs.myChild.onactiveIndex("0")
      }
    },
  },
  created() {
    if(sessionStorage.getItem('Internationalization')=='EN'){
      this.language = EN;
    }else{
      this.language = CH;
    }
  },
  mounted(){
    window.addEventListener('scroll',this.onScoll,true);
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScoll,true);
  }
}
</script>
<style lang="scss" scoped>
  .top_div{
    background: linear-gradient(69.17deg, rgba(185, 11, 11, 1) 1.14%, rgba(224, 34, 34, 1) 110.31%);
    width: 100%;
    min-width: 1440px;
    height: 870px;
    margin-top: 82px;
  }
  .bg_top{
    background: url("../assets/images/banner-bg.png") no-repeat;
    background-size: 100% 100%;
    width: 1440px;
    padding: 128px 120px 60px;
    box-sizing: border-box;
    margin: 0 auto;
    .title_p{
      width: 514px;
      height: 160px;
      color: rgba(255, 255, 255, 1);
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 50px;
      line-height: 80px;
      margin-bottom: 21px;
    }
    .title_pen{
      width: 660px;
      font-size: 37px;
      line-height: 50px;
    }
    .content_1{
      width: 438px;
      height: 113px;
      color: rgba(255, 255, 255, 1);
      font-family: PingFang SC;
      font-size: 16px;
      line-height: 24px;
    }
    .content_1en{
      width: 624px;
      height: 120px;
      font-size: 14px;
    }
    .oneUl{
      display: flex;
      margin-top: 94px;
      li{
        width: calc(100%/3 - 22px);
        margin-right: 32px;
        padding: 30px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 4px 8px 6px rgba(0, 0, 0, 0.12);
        img{
          width: 72px;
          height: 72px;
        }
        .p1{
          height: 60px;
          color: rgba(37, 40, 66, 1);
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 24px;
          line-height: 60px;
        }
        .hr{
          width: 69px;
          height: 2px;
          background: rgba(203, 1, 1, 1);
          margin-bottom: 4px;
        }
        .p2{
          height: 84px;
          color: rgba(102, 102, 102, 1);
          font-family: PingFang SC;
          font-size: 16px;
          line-height: 28px;
        }
        .p2en{
          height: 110px;
          font-size: 13px;
          line-height: 22px;
        }
      }:last-child{
        margin-right: 0;
        }
      li:hover{
        box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.4);
      }
    }
  }
  .title{
    /*margin: 0 auto;*/
    /*width: 644px;*/
    text-align: center;
    height: 80px;
    padding-top: 80px;
    display: flex;
    justify-content: center;
    .headline{
      height: 80px;
      text-align: center;
      color: rgba(37, 40, 66, 1);
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 40px;
      line-height: 80px;
      padding: 0 31px;
    }
    img{
      width: 218px;
      height: 18px;
      align-self: center;
    }
  }
  .caption{
    color: rgba(102, 102, 102, 1);
    font-family: PingFang SC;
    font-size: 16px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 50px;
  }
  .content1{
    width: 1440px;
    margin: 0 auto;
    height: 878px;
    background: url("../assets/images/content1-bg.png") no-repeat;
    background-size: 100% 100%;
    .carousel{
      .carouseUl{
        display: flex;
        padding: 0 120px;
        box-sizing: border-box;
        li {
          width: calc(100%/4 - 15px);
          text-align: center;
          margin-right: 20px;
          margin-top: 10px;
          padding: 20px;
          border-radius: 8px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 2px 1px 6px rgba(0, 0, 0, 0.12);
          .headPortrait{
            width: 144px;
            height: 144px;
            margin-bottom: 10px;
          }
          h1{
            height: 60px;
            text-align: center;
            color: rgba(37, 40, 66, 1);
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 36px;
            line-height: 60px;
            margin-bottom: 6px;
          }
          .inlabel{
            display: flex;
            justify-content: center;
            h3{
              border-radius: 4px;
              background: rgba(255, 213, 189, 1);
              padding: 5px 8px;
              height: 20px;
              text-align: center;
              color: rgba(37, 40, 66, 1);
              font-family: PingFang SC;
              font-size: 15px;
              line-height: 20px;
            }:last-child{
              margin-left: 8px;
            }
          }
          .p4{
            margin-top: 25px;
            /*height: 118px;*/
            text-align: center;
            color: rgba(102, 102, 102, 1);
            font-family: PingFang SC;
            font-size: 14px;
            line-height: 20px;
            padding-bottom: 20px;
            min-height: 90px;
          }
          .p4en{
            min-height: 160px;
          }
          .icon_play{
            width: 22px;
            height: 22px;
            cursor: pointer;
          }
        }:last-child{
          margin-right: 0;
        }
      }
    }
    /deep/ .el-carousel__container{
      height: 560px;
    }
  }
  .content2{
    width: 1440px;
    margin: 0 auto;
    .inul{
      padding: 0 120px;
      box-sizing: border-box;
      .li{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        position: relative;
        div{
          flex: 1;
          .FrameL{
            width: 129px;
            height: 96px;
          }
          .FrameR{
            position: absolute;
            top: 0;
            right: 0;
            width: 129px;
            height: 96px;
          }
          .p5{
            height: 60px;
            margin-left: 64px;
            color: rgba(0, 0, 0, 1);
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 40px;
            line-height: 60px;
            margin-bottom: 14px;
          }
          .p5en{
            height: 120px;
          }
          .p6{
            height: 26px;
            margin-left: 64px;
            color: rgba(102, 102, 102, 1);
            font-family: PingFang SC;
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 14px;
          }
          .p7{
            height: 32px;
            line-height: 32px;
            border-radius: 16px;
            background: rgba(255, 184, 184, 1);
            text-align: center;
            color: rgba(0, 0, 0, 1);
            font-family: PingFang SC;
            font-size: 16px;
            margin-left: 64px;
            display: inline-block;
            padding: 3px 15px;
          }
        }
        .online{
          width: 633px;
          height: 400px;
        }
      }
    }
  }
  .content3{
    width: 1440px;
    margin: 0 auto;
    height: 880px;
    background: url("../assets/images/student-bg.png") no-repeat;
    background-size: 100% 100%;
    .carousel{
      .carousediv{
        display: flex;
        flex-wrap: wrap;
        padding: 0 120px;
        box-sizing: border-box;
        li{
          width: calc(100%/6 - 16.7px);
          margin: 0px 20px 20px 0;
          overflow: hidden;
          img{
            width: 100%;
          }
        }:nth-child(6n){
           margin-right: 0;
        }
        img:hover{
          transform: scale(1.2);
          transition: 2s all;
          overflow: hidden;
        }
      }
    }
    /deep/ .el-carousel__container{
      height: 600px;
    }
  }
  .content4{
    width: 1440px;
    margin: 0 auto;
    height: 818px;
    background: url("../assets/images/us_bg.png") no-repeat;
    background-size: 100% 100%;
    .indiv{
      padding: 0 120px;
      box-sizing: border-box;
      .hr{
        width: 69px;
        height: 2px;
        background: rgba(203, 1, 1, 1);
        margin-bottom: 4px;
      }
      .p8{
        height: 60px;
        color: rgba(0, 0, 0, 1);
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 36px;
        line-height: 60px;
        margin-top: 28px;
      }
      .p9{
        width: 539px;
        height: 168px;
        color: rgba(0, 0, 0, 1);
        font-family: PingFang SC;
        font-size: 16px;
        line-height: 28px;
        margin-top: 15px;
      }
    }
  }
  .buttom_div{
    background: rgba(37, 40, 66, 1);
    width: 100%;
    min-width: 1440px;
  }
  .bg_buttom{
    height: 328px;
    width: 1440px;
    background: rgba(37, 40, 66, 1);
    padding: 0 120px;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    .div_L{
      flex: 1;
      align-self: center;
      .p11{
        color: rgba(255, 255, 255, 1);
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 24px;
        line-height: 34px;
      }
      .p12{
        font-size:16px;
        font-weight: 300;
      }
    }
    .div_R{
      display: flex;
      justify-content: space-between;
      align-self: center;
      width: 438px;
      img{
        width: 160px;
        height: 160px;
      }
      .p10{
        margin-top: 12px;
        text-align: center;
        height: 20px;
        color: rgba(255, 255, 255, 1);
        font-family: PingFang SC;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .footnote{
    height: 69px;
    width: 100%;
    background: rgba(31, 34, 56, 1);
    padding: 0 120px;
    box-sizing: border-box;
    margin: 0 auto;
    color: rgba(255, 255, 255, 1);
    font-family: PingFang SC;
    font-size: 16px;
    line-height: 69px;
    text-align: center;
  }
  .video{
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    .vidio{
      width: 900px;
      height: 480px;
      margin: -240px 0 0 -450px;
      background: black;
      position: absolute;
      top: 50%;
      left:50%;
      video{
        width: 100%;
      }
      i{
        position: absolute;
        top: 10px;
        right: 10px;
        color: aliceblue;
      }
    }

  }
  /deep/ .el-carousel__button{
    height: 8px;
    width: 15px;
    border-radius: 100px;
    background: rgba(203, 1, 1, 1);
  }
  /deep/ .is-active .el-carousel__button {
    width: 32px;
  }
</style>
