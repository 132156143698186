<template>
    <div class="Header">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
        <img class="logo" @click="anchor('scrTo')" :src="icon1" alt="">
        <el-menu-item @click="anchor(item.anchor)" :index="item.id" v-for="item in language.headerList" :key="item.id">{{item.text}}</el-menu-item>
        <el-button round @click="onInternationalization">{{language.type=='EN'?'中文':'English'}}</el-button>
      </el-menu>
    </div>
</template>

<script>
  import CH from '../assets/lang/CH.json'
  import EN from '../assets/lang/EN.json'
    export default {
        name: "Header",
      data(){
        return{
          activeIndex:"",
          icon1:require('@/assets/images/logo.png')
        }
      },
      created() {
          console.log(this.$root)
        if(sessionStorage.getItem('Internationalization')=='EN'){
          this.language = EN;
        }else{
          this.language = CH;
        }
      },
      methods:{
        onInternationalization(){
          if(this.language==EN){
            sessionStorage.setItem('Internationalization','CH')
            this.language=CH
          }else {
            sessionStorage.setItem('Internationalization','EN')
            this.language=EN
          }

          this.$emit('onInternationa',this.language)
        },
        anchor(anchor){
          console.log(anchor)
          this.$parent.Select(anchor)
        },
        onactiveIndex(activeIndex){
          this.activeIndex=activeIndex
        }
      }
    }
</script>

<style lang="scss" scoped>
  .Header{
    width: 100%;
    min-width: 1440px;
    padding: 0 120px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    z-index: 1000;
    background: #fff;
  }
  .el-menu.el-menu--horizontal{
    border-bottom: none;
   /* padding: 0 120px;
    min-width: 1440px;
    box-sizing: border-box;*/
    width: 1200px;
    margin: 0 auto;
    .el-menu-item{
      height: 80px;
      line-height: 80px;
      margin: 0 43px;
    }
  }
  .logo{
    width: 80px;
    height: 80px;
    float: left;
  }
  .el-button.is-round{
    margin-top: 20px;
    float: right;
  }
  .el-button:focus{
    color: #000000;
    border-color:  #000000;
    background-color: #fff
  }
  .el-button:hover {
    color: rgba(203, 1, 1, 1);
    border-color: rgba(203, 1, 1, 1);
    background-color: rgba(255, 228, 228, 1);
  }
  .el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: 2px solid rgba(203, 1, 1, 1);
  }
</style>
